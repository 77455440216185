import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import HotelItem from '../components/hotel-item'

export default ({ data: { allMarkdownRemark: { totalCount, edges } }, pageContext: { type } }) => {
  return (
    <Layout>
      <div className='container'>
        <div className='md:flex -mx-2 py-8'>
          <div className='md:w-3/12 px-2'>
            <div className='bg-white'>
              Filters
          </div>
          </div>
          <div className='md:w-9/12 px-2'>
            <div className='bg-white p-6'>
              <h1 className='text-xl font-bold'>{type} ({totalCount} gasite)</h1>
            </div>
            <div className="flex flex-wrap -mx-2">
              {edges.map(({ node }) => <HotelItem key={node.frontmatter.position} property={node} />)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query ($type: String!) {
  allMarkdownRemark(
    sort: {
      order: [DESC, ASC],
      fields: [frontmatter___featured, frontmatter___position]
    }
    filter: {
      frontmatter: {
        type: {eq: $type}
        disabled: {eq: false}
      }
    }){
    totalCount
    edges {
      node {
        frontmatter {
          position
          title
          type
          featured
          gallery {
            avatar {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          price {
            price_value
            price_per
            price_info
          }
          reasons
        }
        fields {
          slug
        }
      }
    }
  }
}
`