import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"

const HotelItem = ({ property }) => {
  return (
    <Link to={property.fields.slug} className='block mt-4 w-full sm:w-1/2 px-2 md:w-full'>
      <div className="bg-white h-full flex flex-col md:flex-row hover:shadow">
        <div className='h-56 md:w-2/5 md:h-auto lg:w-1/4'>
          {property.frontmatter.gallery && property.frontmatter.gallery.avatar
            ? <Img fixed={property.frontmatter.gallery.avatar.childImageSharp.fluid} alt={property.frontmatter.title} className="w-full h-full align-top" />
            : <img src='https://placehold.co/206x184' alt="hotel placeholder" />}
        </div>
        <div className="flex-1 p-4 md:p-6 sm:flex sm:flex-col md:block md:w-3/5 lg:flex lg:flex-row lg:w-3/4">
          <div className="flex-1">
            <div className='text-xs flex mb-2 text-gray-500'>
              {property.frontmatter.featured && <span className='bg-blue-500 text-white block px-1 rounded mr-4'>Recomandat</span>}
              {property.frontmatter.type.toUpperCase()}
            </div>
            <div className='text-xl mb-2'>{property.frontmatter.title}</div>
            <ul className='text-sm list-disc list-inside mt-2 text-gray-700'>
              {property.frontmatter.reasons.map((reason, i) => (
                <li key={i}>{reason}</li>
              ))}
            </ul>
          </div>
          <div className='flex items-center mt-3 border-solid border-gray-300 lg:flex-col lg:justify-end lg-text-right lg:border-l lg:pl-16'>
            <span className='bg-blue-500 text-white rounded inline-block px-4 py-2 lg:order-last hover:bg-blue-600'>Vezi detalii</span>
            {property.frontmatter.price && (
              <div className='ml-4 lg:text-right lg:mb-4 lg:ml-0'>
                <div className='text-xl text-blue-500 leading-none'>{property.frontmatter.price.price_value} lei</div>
                <div className='text-xs text-gray-500'>{property.frontmatter.price.price_per}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default HotelItem;